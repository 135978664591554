import { SET_INVOICE_CODE } from './-mutation-types';
import { useInvoiceCodesStore } from '~/stores/invoice-codes';
import InvoiceCodeService from '~/services/api/crm/InvoiceCodeService';
import { type InvoiceCode } from '~/models/InvoiceCode';

const Actions = {
    async fetchInvoiceCode(invoiceCodeId: number) {
        const invoiceCodesStore = useInvoiceCodesStore();
        const invoiceCodeService = new InvoiceCodeService();
        const response = await invoiceCodeService.fetchInvoiceCode(invoiceCodeId);

        invoiceCodesStore[SET_INVOICE_CODE](response);
    },
    setInvoiceCode(InvoiceCode: InvoiceCode) {
        const invoiceCodesStore = useInvoiceCodesStore();
        invoiceCodesStore[SET_INVOICE_CODE](InvoiceCode);
    },
};

export default Actions;
