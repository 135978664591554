import { type InvoiceCode } from '~/models/InvoiceCode';

export type InvoiceCodeState = {
    invoiceCode: InvoiceCode | null;
}

export const defaultStateData = (): InvoiceCodeState => ({
    invoiceCode: null,
});

export const DefaultState = (): InvoiceCodeState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
