import { GET_INVOICE_CODE } from './-getter-types';
import { useInvoiceCodesStore } from '~/stores/invoice-codes';
import { type InvoiceCode } from '~/models/InvoiceCode';

const Getters = {
    [GET_INVOICE_CODE]: (): InvoiceCode | null => {
        const invoiceCodesStore = useInvoiceCodesStore();

        return invoiceCodesStore.invoiceCode;
    },
};

export default Getters;
