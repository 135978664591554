import { SET_INVOICE_CODE } from './-mutation-types';
import { useInvoiceCodesStore } from '~/stores/invoice-codes';
import { type InvoiceCode } from '~/models/InvoiceCode';

const Mutations = {
    [SET_INVOICE_CODE](payload: InvoiceCode | null) {
        const invoiceCodesStore = useInvoiceCodesStore();
        invoiceCodesStore.invoiceCode = payload;
    },
};

export default Mutations;
